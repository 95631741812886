import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { IScannerRespondentGroup } from 'pages/Dashboards/Scanner/Scanner/types'

const TypeEvaluation: { [key: string]: string } = {
  '3': 'Alpha',
  '4': 'Via',
  '5': 'Temperamentos',
  '6': 'Motivacional',
  '9': 'Objeções',
}

export class RespondentsExcel {
  private workbook = new ExcelJS.Workbook()
  private respondents: IScannerRespondentGroup[]
  private title: string

  constructor(respondents: IScannerRespondentGroup[], title: string) {
    this.respondents = respondents
    this.title = title
  }

  private async createBasicRespondent() {
    const respondentsSheet = this.workbook.addWorksheet('Relatório', {
      views: [{ state: 'frozen', ySplit: 1 }],
    })

    respondentsSheet.columns = [
      {
        header: 'N°',
        key: 'number',
        width: 7,
        font: { bold: true },
      },
      {
        header: 'Nome',
        key: 'name',
        width: 40,
        font: { bold: true },
      },
      {
        header: 'Teste',
        key: 'evaluation',
        width: 25,
        font: { bold: true },
      },
      {
        header: 'Status',
        key: 'status',
        width: 15,
        font: { bold: true },
      },
    ]

    this.respondents.forEach((res, index) => {
      const rows: any[] = []
      res.evaluations.forEach((ev) => {
        rows.push({
          number: index + 1,
          name: ev.name,
          evaluation: TypeEvaluation[ev.type_evaluation],
          status: ev.done ? 'Sim' : 'Não',
        })
      })
      respondentsSheet.addRows(rows)
      const currentRowIdx = respondentsSheet.rowCount
      respondentsSheet.mergeCells(currentRowIdx - rows.length + 1, 1, currentRowIdx, 1)
      respondentsSheet.mergeCells(currentRowIdx - rows.length + 1, 2, currentRowIdx, 2)
      const color = res.evaluations.find((ev) => !ev.done) ? 'ffff01' : '93d04f'
      respondentsSheet.getCell(currentRowIdx, 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color },
      }
      respondentsSheet.getCell(currentRowIdx, 2).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color },
      }
    })

    respondentsSheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      const color = row.getCell(4).value === `Sim` ? `93d04f` : `ffff01`
      row.eachCell({ includeEmpty: true }, (cell, cellNumber) => {
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        if (cellNumber === 1) {
          cell.alignment = { vertical: 'middle', horizontal: 'center' }
        } else {
          cell.alignment = { vertical: 'middle' }
        }

        if ((cellNumber === 3 || cellNumber === 4) && rowNumber !== 1) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: color },
          }
        }
      })
    })
  }

  private async save() {
    const buffer = await this.workbook.xlsx.writeBuffer()
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    const blob = new Blob([buffer], { type: fileType })

    saveAs(blob, this.title.trim())
  }

  public async create() {
    await this.createBasicRespondent()
    await this.save()
  }
}
