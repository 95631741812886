import { create } from 'zustand'

export interface IOption {
  value: any
  label: string
}

export interface IUseEvaluationSelectorStoreCourse {
  id: string
  name: string
}

export interface IUseEvaluationSelectorStoreCompany {
  id?: string
  commercial_name: string
  company_name?: string
  phone?: string
  cnpj?: number
}

export interface IUseEvaluationSelectorStoreSector {
  sector_name: string
  commercial_name: string
  id: string
}

export interface IUseEvaluationSelectorStoreEvaluation {
  name: string
  created_at: string
  user_id: string
  evaluation_id: string
}

export interface IUseEvaluationSelectorStoreScanner {
  created_at: string
  type_evaluation: string
  id: string
}

interface IUseEvaluationSelectorStore {
  loading: boolean
  setLoading: (loading: boolean) => void

  companies: IUseEvaluationSelectorStoreCompany[]
  selectedCompanies: IUseEvaluationSelectorStoreCompany[]
  setCompanies: (companies: IUseEvaluationSelectorStoreCompany[]) => void
  setSelectedCompanies: (selectedCompanies: IUseEvaluationSelectorStoreCompany[]) => void

  courses: IUseEvaluationSelectorStoreCourse[]
  selectedCourses: IUseEvaluationSelectorStoreCourse[]
  setCourses: (courses: IUseEvaluationSelectorStoreCourse[]) => void
  setSelectedCourses: (selectedCourses: IUseEvaluationSelectorStoreCourse[]) => void

  sectors: IUseEvaluationSelectorStoreSector[]
  selectedSectors: IUseEvaluationSelectorStoreSector[]
  setSectors: (sectors: IUseEvaluationSelectorStoreSector[]) => void
  setSelectedSectors: (selectedSectors: IUseEvaluationSelectorStoreSector[]) => void

  evaluations: IUseEvaluationSelectorStoreEvaluation[]
  selectedEvaluations: IUseEvaluationSelectorStoreEvaluation[]
  setEvaluations: (evaluations: IUseEvaluationSelectorStoreEvaluation[]) => void
  setSelectedEvaluations: (selectedEvaluations: IUseEvaluationSelectorStoreEvaluation[]) => void

  scannerEvaluations: IUseEvaluationSelectorStoreScanner[]
  selectedScannerEvaluations: IUseEvaluationSelectorStoreScanner[]
  setScannerEvaluations: (evaluations: IUseEvaluationSelectorStoreScanner[]) => void
  setSelectedScannerEvaluations: (selectedEvaluations: IUseEvaluationSelectorStoreScanner[]) => void

  clear: () => void
  clearSelected: () => void

  isSuperAdmin?: boolean
}

export const useEvaluationSelectorStore = create<IUseEvaluationSelectorStore>((set) => ({
  loading: false,
  setLoading: (loading) => set({ loading }),

  companies: [],
  selectedCompanies: [],
  setCompanies: (companies) => set({ companies }),
  setSelectedCompanies: (selectedCompanies) => set({ selectedCompanies }),

  courses: [],
  selectedCourses: [],
  setCourses: (courses) => set({ courses }),
  setSelectedCourses: (selectedCourses) => set({ selectedCourses }),

  sectors: [],
  selectedSectors: [],
  setSectors: (sectors) => set({ sectors }),
  setSelectedSectors: (selectedSectors) => set({ selectedSectors }),

  evaluations: [],
  selectedEvaluations: [],
  setEvaluations: (evaluations) => set({ evaluations }),
  setSelectedEvaluations: (selectedEvaluations) => set({ selectedEvaluations }),

  scannerEvaluations: [],
  selectedScannerEvaluations: [],
  setScannerEvaluations: (evaluations) => set({ scannerEvaluations: evaluations }),
  setSelectedScannerEvaluations: (selectedEvaluations) => set({ selectedScannerEvaluations: selectedEvaluations }),

  clear: () =>
    set({
      companies: [],
      selectedCompanies: [],
      courses: [],
      selectedCourses: [],
      sectors: [],
      selectedSectors: [],
      evaluations: [],
      selectedEvaluations: [],
      scannerEvaluations: [],
      selectedScannerEvaluations: [],
    }),

  clearSelected: () =>
    set({
      selectedCompanies: [],
      selectedCourses: [],
      selectedSectors: [],
      selectedEvaluations: [],
      selectedScannerEvaluations: [],
    }),

  isSuperAdmin: false,
}))
