import { Flex, Radio, RadioGroup, Text, Textarea } from '@chakra-ui/react'
import React from 'react'
import { Controller } from 'react-hook-form'

interface IQuestion {
  text: string
  completeIfBiggerThan?: number | null
  completeIfSmallerThan?: number | null
  completeText?: string | null
}

interface IQuestionComp {
  question: IQuestion
  index: number
  options?: string[]
  control?: any
  page?: number
  previousText?: string
}

const QuestionComp: React.FC<IQuestionComp> = (props) => {
  const {
    question,
    index,
    control,
    page,
    options = [
      'Discordo totalmente',
      'Discordo parcialmente',
      'Não concordo nem discordo',
      'Concordo parcialmente',
      'Concordo totalmente',
    ],
    previousText,
  } = props

  return (
    <>
      {previousText && (
        <Flex margin="0 2.5rem">
          <Text fontSize="1rem">{previousText}</Text>
        </Flex>
      )}

      <Flex margin="0.9375rem 2.5rem" marginTop={previousText ? '0' : '0.9375rem'}>
        <Text fontSize="1.25rem">{`${index + 1}. ${question.text}`}</Text>
        <Text color="red" marginLeft="0.625rem">
          *
        </Text>
      </Flex>

      <Controller
        name={`${page}.${index}`}
        control={control}
        rules={{
          required: {
            value: true,
            message: `Por favor, preencha a questão ${index + 1}!`,
          },
        }}
        defaultValue={[]}
        render={({ field }) => {
          return (
            <>
              <RadioGroup
                display="flex"
                flexDirection="column"
                margin="0.625rem 3.75rem"
                value={field.value?.[0] || []}
                onChange={(evt) => field.onChange([evt, field.value?.[1]])}
              >
                {options.map((option, index) => (
                  <Radio key={option} paddingBlock={2} value={index.toString()}>
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
              {(question.completeIfBiggerThan && +field.value?.[0] >= question.completeIfBiggerThan) ||
              (question.completeIfSmallerThan &&
                +field.value?.[0] > 0 &&
                +field.value?.[0] <= question.completeIfSmallerThan) ? (
                <>
                  <Flex margin="2.5rem">
                    <Text fontSize="1.25rem">{question.completeText}</Text>
                    <Text color="red" marginLeft="0.625rem">
                      *
                    </Text>
                  </Flex>
                  <Flex w="100%" margin="0rem 0rem 2.5rem 2.5rem">
                    <Textarea
                      size="sm"
                      w="80%"
                      resize="none"
                      placeholder="Insira sua resposta"
                      defaultValue=""
                      value={field.value?.[1]}
                      onChange={(evt) => field.onChange([field.value?.[0], evt.target.value])}
                    />
                  </Flex>
                </>
              ) : null}
            </>
          )
        }}
      />
    </>
  )
}

export default QuestionComp
