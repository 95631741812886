import { Box, Flex, Text } from '@chakra-ui/layout';
import { motion } from 'framer-motion';
import { ConsolidatedChart } from 'layouts/dash/types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import { CountResponses } from './types';

export default function EstresseConsolidated(props: ConsolidatedChart) {
  const { evaluations } = props;
  const [info, setInfo] = useState<CountResponses | null>(null);

  useEffect(() => {
    api
      .post(`/tableresponse/evaluations`, { evaluations: evaluations })
      .then(({ data }) => setInfo(data))
      .catch(() => toast.error('Erro ao buscar dados'));
  }, [evaluations]);

  if (!info) return null;

  return (
    <Flex direction="column" w="100%" h="100%" justifyContent="center" alignItems="center">
      <Box
        h="20rem"
        w="20rem"
        bg="blue"
        borderRadius="100%"
        background="conic-gradient(from -120deg, #85e291 0deg 60deg, #ffff00 60deg 120deg, #ec8a05 120deg 180deg, #fe0000 180deg 240deg, transparent 240deg 360deg)"
        border="2px solid black"
        position="relative"
      >
        <svg
          style={{
            position: 'absolute',
            bottom: '50%',
            right: '50%',
            rotate: `${-75 + (info?.groupTotal / 56) * 240}deg`,
            transformOrigin: 'bottom right',
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="2.5rem"
          height="2.5rem"
          viewBox="0 0 15 15"
        >
          <path
            fill="currentColor"
            d="M4.5 0h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .854.354L2.5 3.207l11.646 11.647l.708-.708L3.207 2.5L4.854.854A.5.5 0 0 0 4.5 0"
          />
        </svg>

        <Box
          w="50%"
          h="2rem"
          position="absolute"
          right="50%"
          bottom="calc(50% - 1rem)"
          transformOrigin="center right"
          transform={`rotate(${-30 + (info?.groupTotal / 56) * 240}deg)`}
        >
          <motion.div
            initial={{ opacity: 0, rotate: '0deg' }}
            animate={{ opacity: 1, rotate: `${-(-30 + (info?.groupTotal / 56) * 240)}deg` }}
            transition={{ duration: 0 }}
          >
            <Text fontSize="0.75rem" fontWeight="700" textAlign="center">
              {`${'Grupo'} ( ${info?.groupTotal} )`}
            </Text>
          </motion.div>
        </Box>

        <Box
          w="0.75rem"
          h="0.75rem"
          position="absolute"
          bottom="50%"
          right="50%"
          transform="translate(50%,50%)"
          bg="black"
          borderRadius="100%"
        />
        <Box
          w="87%"
          h="5rem"
          borderTop="2px solid black"
          position="absolute"
          bottom="-2px"
          left="50%"
          transform="translateX(-50%)"
          bg="white"
        ></Box>
        <Text position="absolute" bottom="20%" left="0%" fontWeight="700">
          0
        </Text>
        <Box
          textAlign="center"
          position="absolute"
          bottom="40%"
          left="-5%"
          transform="translateX(-100%)"
          fontWeight="400"
        >
          <Text fontSize="0.75rem">Baixo estresse percebido</Text>
          <Text fontSize="0.75rem">(0 - 13)</Text>
        </Box>
        <Text position="absolute" bottom="75%" left="0%" fontWeight="700">
          13
        </Text>
        <Box
          textAlign="center"
          position="absolute"
          bottom="95%"
          left="25%"
          transform="translateX(-100%)"
          fontWeight="400"
        >
          <Text fontSize="0.75rem">Estresse moderado</Text>
          <Text fontSize="0.75rem">(13-27)</Text>
        </Box>
        <Text position="absolute" bottom="100%" left="50%" transform="translateX(-50%)" fontWeight="700">
          27
        </Text>
        <Box
          textAlign="center"
          position="absolute"
          bottom="95%"
          right="25%"
          transform="translateX(100%)"
          fontWeight="400"
        >
          <Text fontSize="0.75rem">Estresse moderado</Text>
          <Text fontSize="0.75rem">(13-27)</Text>
        </Box>
        <Text position="absolute" bottom="75%" right="0%" fontWeight="700">
          40
        </Text>
        <Box
          textAlign="center"
          position="absolute"
          bottom="40%"
          right="-5%"
          transform="translateX(100%)"
          fontWeight="400"
        >
          <Text fontSize="0.75rem">Muito alto estresse percebido</Text>
          <Text fontSize="0.75rem">(41-56)</Text>
        </Box>
        <Text position="absolute" bottom="20%" right="-2.5%" fontWeight="700">
          56
        </Text>
      </Box>

      <Text fontWeight="800" align="center" marginTop="0.625rem" fontSize="1.25rem">
        Nível de estresse percebido (PSS-14)
      </Text>
      <Text
        as="i"
        fontWeight="400"
        align="center"
        marginTop="0.625rem"
        fontSize="0.875rem"
        maxWidth="700"
        color="gray.500"
      >
        Este gráfico representa um velocímetro interpretando os níveis de estresse segundo a pontuação da Escala de
        Estresse percebido (PSS-14). Ele ilustra a sua pontuação através do ponteiro em uma escala de 0 a 56, sendo
        dividida em 4 faixas.
      </Text>
    </Flex>
  );
}
