export const OperacionalColor = {
  totallyAgree: '#fdc300',
  agree: '#c59300',
  middle: '#a7a7a7',
  disagree: '#5f5f5f',
  totallyDisagree: '#222222',
}

export const TaticoColor = {
  totallyAgree: '#FFC900',
  agree: '#C26700',
  middle: '#0A0C0B',
  disagree: '#575C55',
  totallyDisagree: '#7F8D8C',
}
