import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer'
import { Cover, ReportFooter, ReportHeader } from 'components/Reports'
import { pageStyle } from 'components/Reports/styles'
import * as questions from 'pages/Dashboards/360/BarChart360/questionsData'
import { useEffect, useState, Fragment } from 'react'

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
})

interface DocumentComponentProps {
  evaluatedName: string
  company: string
  year: number | string
  month: string
  questionsResponse: any[]
  count: number
}

Font.register({
  family: 'Lato',
  src: 'https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf',
})

Font.register({
  family: 'Lato Bold',
  src: 'https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf',
})

const styles = StyleSheet.create({
  legendCircle: {
    marginLeft: 5,
    marginRight: 5,
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
  legendText: {
    fontSize: 8,
    fontWeight: 500,
  },
  legendTitle: {
    fontSize: 16,
    fontWeight: 900,
    textAlign: 'center',
    marginTop: 10,
  },
  legendSubtitle: {
    fontSize: 8,
    fontWeight: 500,
    textAlign: 'right',
    marginLeft: 'auto',
  },
  frontCover: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  reportAllInfos: {
    width: '100%',
  },
  frontCoverFooter: {
    color: '#FFC000',
    textAlign: 'center',
  },
  lateralDiv: {
    backgroundColor: '#FFC000',
    height: '100%',
    width: '3%',
  },
  reportInfos: {
    height: '70%',
    justifyContent: 'center',
    marginLeft: 30,
  },
  reportInfosTitle: {
    fontSize: 30,
    fontWeight: 'bold',
    fontFamily: 'Lato Bold',
    paddingBottom: 4,
    color: '#404040',
  },
  reportInfosSubTitle: {
    fontSize: 12,
    fontStyle: 'italic',
    color: '#404040',
  },
  reportDescription: {
    height: '25%',
    marginLeft: 30,
    color: '#404040',
  },

  content: {
    width: '80%',
    margin: '0 auto',
  },
  title: {
    fontSize: 16,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontFamily: 'Lato',
    color: '#8a7843',
  },
  container: {},
  text: {
    fontSize: 11,
    textAlign: 'justify',
    paddingTop: 12,
  },
  bulletPoint: {
    width: 10,
    fontSize: 12,
  },
  list: {
    paddingTop: 12,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
  },
  listText: {
    fontSize: 11,
    textAlign: 'justify',
  },
  dashTitle: {
    paddingTop: 12,
    paddingBottom: 5,
    textAlign: 'justify',
    fontSize: 11,
    fontWeight: 'bold',
    color: '#2D3748',
    fontFamily: 'Lato Bold',
  },
  dashResult: {
    display: 'flex',
    flexDirection: 'column',
  },
  dashResponseTexts: {
    fontSize: 10,
    textAlign: 'justify',
    color: '#2D3748',
    paddingLeft: 4,
    paddingRight: 20,
    width: 235,
  },
  dashResponse: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 3,
    paddingBottom: 3,
  },
  dashResponseNeutral: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 3,
    paddingBottom: 3,
    borderTop: 1,
    borderBottom: 1,
    borderColor: '#ededed',
  },
  dashResponseQuestion: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dashResponseChart: {
    width: '50%',
    paddingLeft: 8,
  },
  dashResponseOnlyTextsTitle: {
    fontSize: 10,
    textAlign: 'justify',
    color: '#2D3748',
    paddingLeft: 4,
    fontFamily: 'Lato Bold',
  },
  dashResponseOnlyTexts: {
    fontSize: 10,
    textAlign: 'justify',
    color: '#2D3748',
    paddingLeft: 8,
  },
  consolidContainer: {
    marginTop: 15,
    marginLeft: 30,
    marginRight: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  consolidBoxItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 110,
  },
  consolidTitle: {
    textAlign: 'center',
    fontFamily: 'Lato Bold',
    fontSize: 10,
    marginBottom: 21,
  },
  consolidTitleWithoutMargin: {
    textAlign: 'center',
    fontFamily: 'Lato Bold',
    fontSize: 10,
    marginBottom: 6,
  },
  consolidBox: {
    border: '1px solid #000',
    borderRadius: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  consolidBoxSubTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  consolidSubtitle: {
    textAlign: 'center',
    color: 'grey',
    fontSize: 9,
    paddingTop: 5,
  },
  consolidSubtitleBottom: {
    textAlign: 'center',
    color: 'grey',
    fontSize: 9,
    paddingBottom: 5,
  },
  consolidBoxContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 150,
    width: 50,
  },
  consolidChartText: {
    fontSize: 8,
    fontFamily: 'Lato Bold',
    color: '#ffffff',
  },
  consolidBoxContentSecond: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 150,
    width: 50,
  },
  dashFlexChar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    width: '13px',
    height: '13px',
  },
  bold: {
    fontFamily: 'Lato Bold',
  },
})

const LegendTextConsolidated = (props: { qtyUsers: number }) => {
  const { qtyUsers } = props
  return (
    <>
      <View style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
        <Text
          style={{
            ...styles.legendCircle,
            backgroundColor: '#fdc300',
          }}
        />
        <Text style={styles.legendText}>Pontos positivos</Text>
        <Text
          style={{
            ...styles.legendCircle,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          }}
        />
        <Text style={styles.legendText}>Pontos negativos</Text>
        <Text
          style={{
            ...styles.legendCircle,
            backgroundColor: 'rgb(113, 128, 150)',
          }}
        />
        <Text style={styles.legendText}>Não sei opinar</Text>
        <Text
          style={{
            ...styles.legendCircle,
            backgroundColor: 'rgb(255, 144, 0)',
          }}
        />
        <Text style={styles.legendText}>Autoavaliação</Text>
        <Text style={styles.legendSubtitle}>{`Número de respostas: ${qtyUsers}`}</Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 20,
          marginHorizontal: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Text
          style={{
            marginHorizontal: 'auto',
            fontSize: 12,
            fontFamily: 'Lato Bold',
            fontWeight: 'bold',
          }}
        >
          Mapeamento 360 graus
        </Text>
        <Text
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
            marginHorizontal: 'auto',
            maxWidth: 310,
            fontSize: 9,
          }}
        >
          Barras amarelas na parte superior representam afirmações positivas, enquanto barras cinza escuro na inferior
          mostram as negativas. Percentuais de 0 a 100% em cada barra refletem a concordância dos avaliadores. Cinza
          claro para &quot;não sei avaliar&quot; indica abstenções, e um marcador alaranjado destaca a autoavaliação.
        </Text>
      </View>
    </>
  )
}

const DocumentComponent = ({ evaluatedName, company, questionsResponse, count }: DocumentComponentProps): any => {
  const reportFullName = `RELATÓRIO AVALIAÇÃO 360° - Plataforma Carnelossi Desenvolvimento`
  const [responsesQuestions, setResponsesQuestions] = useState<any[]>([])

  useEffect(() => {
    setResponsesQuestions([])
    if (questionsResponse) {
      const response1Array = questions.response1.map((r: any) => {
        let questionResponse: any
        if (r.type === 1) {
          questionResponse = questionsResponse[0].positive.find((qr: any) => qr.value == r.value)
        } else if (r.type === 2) {
          questionResponse = questionsResponse[0].negative.find((qr: any) => qr.value == r.value)
        } else {
          questionResponse = questionsResponse[0].neutral.find((qr: any) => qr.value == r.value)
        }
        return {
          value: r.value,
          text: r.text,
          type: r.type,
          percent_response: questionResponse?.percent_response || '0%',
          qtt_response: questionResponse?.qtt_response || '0',
          selected_auto: questionResponse?.selected_auto || 'false',
        }
      })

      const response2Array = questions.response2.map((r: any) => {
        let questionResponse: any
        if (r.type === 1) {
          questionResponse = questionsResponse[1].positive.find((qr: any) => qr.value == r.value)
        } else if (r.type === 2) {
          questionResponse = questionsResponse[1].negative.find((qr: any) => qr.value == r.value)
        } else {
          questionResponse = questionsResponse[1].neutral.find((qr: any) => qr.value == r.value)
        }
        return {
          value: r.value,
          text: r.text,
          type: r.type,
          percent_response: questionResponse?.percent_response || '0%',
          qtt_response: questionResponse?.qtt_response || '0',
          selected_auto: questionResponse?.selected_auto || 'false',
        }
      })

      const response3Array = questions.response3.map((r: any) => {
        let questionResponse: any
        if (r.type === 1) {
          questionResponse = questionsResponse[2].positive.find((qr: any) => qr.value == r.value)
        } else if (r.type === 2) {
          questionResponse = questionsResponse[2].negative.find((qr: any) => qr.value == r.value)
        } else {
          questionResponse = questionsResponse[2].neutral.find((qr: any) => qr.value == r.value)
        }
        return {
          value: r.value,
          text: r.text,
          type: r.type,
          percent_response: questionResponse?.percent_response || '0%',
          qtt_response: questionResponse?.qtt_response || '0',
          selected_auto: questionResponse?.selected_auto || 'false',
        }
      })

      const response4Array = questions.response4.map((r: any) => {
        let questionResponse: any
        if (r.type === 1) {
          questionResponse = questionsResponse[3].positive.find((qr: any) => qr.value == r.value)
        } else if (r.type === 2) {
          questionResponse = questionsResponse[3].negative.find((qr: any) => qr.value == r.value)
        } else {
          questionResponse = questionsResponse[3].neutral.find((qr: any) => qr.value == r.value)
        }
        return {
          value: r.value,
          text: r.text,
          type: r.type,
          percent_response: questionResponse?.percent_response || '0%',
          qtt_response: questionResponse?.qtt_response || '0',
          selected_auto: questionResponse?.selected_auto || 'false',
        }
      })

      const response5Array = questions.response5.map((r: any) => {
        let questionResponse: any
        if (r.type === 1) {
          questionResponse = questionsResponse[4].positive.find((qr: any) => qr.value == r.value)
        } else if (r.type === 2) {
          questionResponse = questionsResponse[4].negative.find((qr: any) => qr.value == r.value)
        } else {
          questionResponse = questionsResponse[4].neutral.find((qr: any) => qr.value == r.value)
        }
        return {
          value: r.value,
          text: r.text,
          type: r.type,
          percent_response: questionResponse?.percent_response || '0%',
          qtt_response: questionResponse?.qtt_response || '0',
          selected_auto: questionResponse?.selected_auto || 'false',
        }
      })

      const response6Array = questions.response6.map((r: any) => {
        let questionResponse: any
        if (r.type === 1) {
          questionResponse = questionsResponse[5].positive.find((qr: any) => qr.value == r.value)
        } else if (r.type === 2) {
          questionResponse = questionsResponse[5].negative.find((qr: any) => qr.value == r.value)
        } else {
          questionResponse = questionsResponse[5].neutral.find((qr: any) => qr.value == r.value)
        }
        return {
          value: r.value,
          text: r.text,
          type: r.type,
          percent_response: questionResponse?.percent_response || '0%',
          qtt_response: questionResponse?.qtt_response || '0',
          selected_auto: questionResponse?.selected_auto || 'false',
        }
      })

      const response7Array = questions.response7.map((r: any) => {
        let questionResponse: any
        if (r.type === 1) {
          questionResponse = questionsResponse[6].positive.find((qr: any) => qr.value == r.value)
        } else if (r.type === 2) {
          questionResponse = questionsResponse[6].negative.find((qr: any) => qr.value == r.value)
        } else {
          questionResponse = questionsResponse[6].neutral.find((qr: any) => qr.value == r.value)
        }
        return {
          value: r.value,
          text: r.text,
          type: r.type,
          percent_response: questionResponse?.percent_response || '0%',
          qtt_response: questionResponse?.qtt_response || '0',
          selected_auto: questionResponse?.selected_auto || 'false',
        }
      })

      const response8Array = questions.response8.map((r: any) => {
        let questionResponse: any
        if (r.type === 1) {
          questionResponse = questionsResponse[7].positive.find((qr: any) => qr.value == r.value)
        } else if (r.type === 2) {
          questionResponse = questionsResponse[7].negative.find((qr: any) => qr.value == r.value)
        } else {
          questionResponse = questionsResponse[7].neutral.find((qr: any) => qr.value == r.value)
        }
        return {
          value: r.value,
          text: r.text,
          type: r.type,
          percent_response: questionResponse?.percent_response || '0%',
          qtt_response: questionResponse?.qtt_response || '0',
          selected_auto: questionResponse?.selected_auto || 'false',
        }
      })

      const response9Array = questions.response9.map((r: any) => {
        let questionResponse: any
        if (r.type === 1) {
          questionResponse = questionsResponse[8].positive.find((qr: any) => qr.value == r.value)
        } else if (r.type === 2) {
          questionResponse = questionsResponse[8].negative.find((qr: any) => qr.value == r.value)
        } else {
          questionResponse = questionsResponse[8].neutral.find((qr: any) => qr.value == r.value)
        }
        return {
          value: r.value,
          text: r.text,
          type: r.type,
          percent_response: questionResponse?.percent_response || '0%',
          qtt_response: questionResponse?.qtt_response || '0',
          selected_auto: questionResponse?.selected_auto || 'false',
        }
      })

      const response10Array = questions.response10.map((r: any) => {
        let questionResponse: any
        if (r.type === 1) {
          questionResponse = questionsResponse[9].positive.find((qr: any) => qr.value == r.value)
        } else if (r.type === 2) {
          questionResponse = questionsResponse[9].negative.find((qr: any) => qr.value == r.value)
        } else {
          questionResponse = questionsResponse[9].neutral.find((qr: any) => qr.value == r.value)
        }
        return {
          value: r.value,
          text: r.text,
          type: r.type,
          percent_response: questionResponse?.percent_response || '0%',
          qtt_response: questionResponse?.qtt_response || '0',
          selected_auto: questionResponse?.selected_auto || 'false',
        }
      })

      // const response2Array = questions.response2.map((r2: any) => {
      //   const questionResponse2 = questionsResponse[1].find(
      //     (qr: any) => qr.response == r2.value
      //   )
      //   return {
      //     value: r2.value,
      //     text: r2.text,
      //     type: r2.type,
      //     percent_response: questionResponse2?.percent_response || '0%',
      //     qtt_response: questionResponse2?.qtt_response || '0',
      //     selected_auto: questionResponse2?.selected_auto || 'false'
      //   }
      // })

      // const response3Array = questions.response3.map((r3: any) => {
      //   const questionResponse3 = questionsResponse[2].find(
      //     (qr: any) => qr.response == r3.value
      //   )
      //   return {
      //     value: r3.value,
      //     text: r3.text,
      //     type: r3.type,
      //     percent_response: questionResponse3?.percent_response || '0%',
      //     qtt_response: questionResponse3?.qtt_response || '0',
      //     selected_auto: questionResponse3?.selected_auto || 'false'
      //   }
      // })

      // const response4Array = questions.response4.map((r4: any) => {
      //   const questionResponse4 = questionsResponse[3].find(
      //     (qr: any) => qr.response == r4.value
      //   )
      //   return {
      //     value: r4.value,
      //     text: r4.text,
      //     type: r4.type,
      //     percent_response: questionResponse4?.percent_response || '0%',
      //     qtt_response: questionResponse4?.qtt_response || '0',
      //     selected_auto: questionResponse4?.selected_auto || 'false'
      //   }
      // })

      // const response5Array = questions.response5.map((r5: any) => {
      //   const questionResponse5 = questionsResponse[4].find(
      //     (qr: any) => qr.response == r5.value
      //   )
      //   return {
      //     value: r5.value,
      //     text: r5.text,
      //     type: r5.type,
      //     percent_response: questionResponse5?.percent_response || '0%',
      //     qtt_response: questionResponse5?.qtt_response || '0',
      //     selected_auto: questionResponse5?.selected_auto || 'false'
      //   }
      // })

      // const response6Array = questions.response6.map((r6: any) => {
      //   const questionResponse6 = questionsResponse[5].find(
      //     (qr: any) => qr.response == r6.value
      //   )
      //   return {
      //     value: r6.value,
      //     text: r6.text,
      //     type: r6.type,
      //     percent_response: questionResponse6?.percent_response || '0%',
      //     qtt_response: questionResponse6?.qtt_response || '0',
      //     selected_auto: questionResponse6?.selected_auto || 'false'
      //   }
      // })

      // const response7Array = questions.response7.map((r7: any) => {
      //   const questionResponse7 = questionsResponse[6].find(
      //     (qr: any) => qr.response == r7.value
      //   )
      //   return {
      //     value: r7.value,
      //     text: r7.text,
      //     type: r7.type,
      //     percent_response: questionResponse7?.percent_response || '0%',
      //     qtt_response: questionResponse7?.qtt_response || '0',
      //     selected_auto: questionResponse7?.selected_auto || 'false'
      //   }
      // })

      // const response8Array = questions.response8.map((r8: any) => {
      //   const questionResponse8 = questionsResponse[7].find(
      //     (qr: any) => qr.response == r8.value
      //   )
      //   return {
      //     value: r8.value,
      //     text: r8.text,
      //     type: r8.type,
      //     percent_response: questionResponse8?.percent_response || '0%',
      //     qtt_response: questionResponse8?.qtt_response || '0',
      //     selected_auto: questionResponse8?.selected_auto || 'false'
      //   }
      // })

      // const response9Array = questions.response9.map((r9: any) => {
      //   const questionResponse9 = questionsResponse[8].find(
      //     (qr: any) => qr.response == r9.value
      //   )
      //   return {
      //     value: r9.value,
      //     text: r9.text,
      //     type: r9.type,
      //     percent_response: questionResponse9?.percent_response || '0%',
      //     qtt_response: questionResponse9?.qtt_response || '0',
      //     selected_auto: questionResponse9?.selected_auto || 'false'
      //   }
      // })

      // const response10Array = questions.response10.map((r10: any) => {
      //   const questionResponse10 = questionsResponse[9].find(
      //     (qr: any) => qr.response == r10.value
      //   )
      //   return {
      //     value: r10.value,
      //     text: r10.text,
      //     type: r10.type,
      //     percent_response: questionResponse10?.percent_response || '0%',
      //     qtt_response: questionResponse10?.qtt_response || '0',
      //     selected_auto: questionResponse10?.selected_auto || 'false'
      //   }
      // })

      setResponsesQuestions([
        response1Array,
        response2Array,
        response3Array,
        response4Array,
        response5Array,
        response6Array,
        response7Array,
        response8Array,
        response9Array,
        response10Array,
      ])
    }
  }, [questionsResponse])

  return (
    <PDFViewer style={{ width: '100%', height: '100%' }}>
      <Document title={reportFullName}>
        <Cover title={`RELATÓRIO\nAVALIAÇÃO 360º`} evaluatedName={evaluatedName} companyName={company} />

        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />

          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.title}>APRESENTAÇÃO</Text>
              <Text style={styles.text}>
                Neste momento, você está recebendo os resultados da sua Avaliação 360°, uma ferramenta detalhada que
                coleta feedback de várias fontes, como superiores, pares, liderados e o próprio avaliado, para avaliar
                diversos aspectos do seu desempenho. Esse processo de mapeamento comportamental, conhecido também como
                feedback 360° ou de múltiplas fontes, analisa sua performance em diferentes níveis de observação. Os
                resultados são fundamentais para identificar áreas que necessitam de desenvolvimento e para a
                implementação de programas adequados a esse fim.
              </Text>
              <Text style={styles.text}>A avaliação 360° segue algumas diretrizes básicas:</Text>
              <View style={styles.list}>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>Anonimato</Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>
                    Distinção: o questionário não contém perguntas do tipo “sim” ou “não”.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>
                    Apresentação dos resultados por frequência relativa: para cada competência avaliada, existem 6
                    opções de respostas positivas e 6 negativas. O avaliador assinala quais opções ele considera
                    pertinentes ao avaliado. Os resultados são apresentados de forma consolidada, mostrando a
                    frequência, representada por um número percentual, com que cada afirmativa foi assinalada.
                  </Text>
                </div>
              </View>
              <Text style={{ ...styles.text, ...styles.bold }}>Descrição do Processo de Avaliação:</Text>
              <Text style={styles.text}>
                A avaliação segue um roteiro planejado, dividido em cinco etapas essenciais:
              </Text>
              <View style={styles.list}>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>1.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Organização da Lista de Avaliadores:</Text> O
                    contratante, seja empresa ou pessoa física, define a lista de avaliadores, incluindo superiores,
                    pares, liderados e, o próprio avaliado, entre outros interessados. Em contexto empresarial, a lista
                    pode ser elaborada por diversos departamentos, como Recursos Humanos, diretoria ou até mesmo pelo
                    avaliado.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>2.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Distribuição de Acesso para Avaliação:</Text> Cada
                    avaliador recebe acesso para realizar a avaliação através do próprio e-mail, assegurando ampla
                    participação.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>3.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Geração do Relatório Consolidado:</Text>{' '}
                    Finalizadas as avaliações, um relatório consolidado é produzido, preservando o anonimato dos
                    participantes.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>4.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Devolutiva da Avaliação:</Text> A devolutiva da
                    avaliação ocorre conforme a opção escolhida no ato da compra, seja por e-mail ou em uma sessão de
                    desenvolvimento. Optando pela sessão, a coach detalhará os resultados, realçando áreas-chave para
                    desenvolvimento e definindo focos de trabalho e priorização de competências, com base na avaliação e
                    objetivos alinhados à organização.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>5.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Acesso ao Relatório pelo Contratante:</Text> Quando
                    o contratante é uma empresa, o relatório é normalmente disponibilizado para o responsável pela área
                    de Recursos Humanos ou diretamente para o dono ou líder direto do avaliado. Isso assegura que o
                    feedback seja utilizado estrategicamente para o desenvolvimento de competências dentro da
                    organização, respeitando a estrutura hierárquica e os canais de comunicação estabelecidos.
                  </Text>
                </div>
              </View>
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={{ ...styles.text, ...styles.bold }}>
                A avaliação 360 graus que realizamos foca em 10 competências essenciais, sendo elas:
              </Text>
              <View style={styles.list}>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>1.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Habilidade de Assumir Responsabilidade:</Text>{' '}
                    Avalia a capacidade do indivíduo de tomar decisões seguras e sustentá-las, destacando coragem e
                    postura diante das dificuldades.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>2.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Planejamento:</Text> Foca na habilidade de criar
                    planos estruturados com metas claras, voltados para resultados a curto, médio e longo prazo.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>3.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Gestão de Tempo e Execução:</Text> Mede a
                    eficiência em organizar, priorizar tarefas e cumprir prazos, além da capacidade de gerir o tempo do
                    time.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>4.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Delegação e Direcionamento:</Text> Examina a
                    clareza no direcionamento das atividades, a adequação da delegação ao nível de competência dos
                    colaboradores e o acompanhamento das tarefas delegadas.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>5.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Feedback Positivo e Negativo:</Text> Analisa a
                    habilidade de dar feedbacks de forma clara, objetiva e respeitosa, contribuindo para a ação de
                    melhoria.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>6.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Comunicação:</Text> Avalia a eficácia da
                    comunicação em ser honesta, clara, sem ruídos e a habilidade de ouvir.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>7.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Relacionamento Interpessoal:</Text> Observa a
                    capacidade de manter bons relacionamentos com respeito, confiança e postura ética, independentemente
                    da hierarquia ou diversidade de perfis.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>8.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Habilidade de Liderar e Formar Times:</Text> Mede a
                    capacidade de liderança, influência, formação de equipe e extração do melhor de cada colaborador.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>9.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Resolução de Problemas e Conflitos:</Text> Examina
                    a rapidez, assertividade e imparcialidade na resolução de problemas e conflitos, bem como a
                    prevenção de futuros desafios.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>10.</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Habilidade de Inovar:</Text> O contratante, seja
                    empresa ou pessoa física, define a lista de avaliadores, incluindo superiores, pares, liderados e, o
                    próprio avaliado, entre outros interessados. Em contexto empresarial, a lista pode ser elaborada por
                    diversos departamentos, como Recursos Humanos, diretoria ou até mesmo pelo avaliado.
                  </Text>
                </div>
              </View>

              <Text style={styles.text}>
                Reforçamos que para cada competência, foram oferecidas{' '}
                <Text style={{ ...styles.text, ...styles.bold }}>6 opções de respostas positivas e 6 negativas</Text>,
                permitindo aos avaliadores expressar suas percepções detalhadamente.
              </Text>
              <Text style={styles.text}>
                Além disso, <Text style={{ ...styles.text, ...styles.bold }}>questões abertas</Text> foram incluídas
                para{' '}
                <Text style={{ ...styles.text, ...styles.bold }}>
                  capturar pontos fortes e fracos do líder, identificar a pessoa mais confiável da equipe e coletar
                  feedbacks não solicitados
                </Text>
                , enriquecendo ainda mais a análise. Essa estrutura busca oferecer uma visão holística e profunda do seu
                desempenho, contribuindo para seu desenvolvimento contínuo.
              </Text>
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.title}>INSTRUÇÕES PARA ANÁLISE DOS RESULTADOS</Text>
              <Text style={{ ...styles.text }}>
                As instruções a seguir guiarão sua análise dos 10 gráficos inclusos neste relatório e apresentados logo
                abaixo:
              </Text>
              <View style={styles.list}>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Avaliação dos pontos positivos e negativos:</Text>{' '}
                    Na parte superior de cada gráfico, você encontrará seis afirmações positivas. Na parte inferior,
                    seis afirmações negativas apontam para pontos de desenvolvimento. Cada afirmação é representada por
                    uma barra gráfica que pode variar de 0% a 100% — as positivas em amarelo e as negativas em cinza
                    escuro — cujos valores percentuais mostram a quantidade de pessoas que concordaram com cada uma
                    delas.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>&quot;Não sei avaliar&quot;:</Text> Marcado em
                    cinza claro, este indicador reflete os avaliadores que se abstiveram de avaliar uma competência
                    específica, excluídos do total de respostas para preservar a precisão dos dados.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>
                    <Text style={{ ...styles.text, ...styles.bold }}>Autoavaliação:</Text> Um marcador alaranjado
                    destaca sua autoavaliação, facilitando a identificação de discrepâncias entre a autopercepção e a
                    visão dos avaliadores, oferecendo uma oportunidade valiosa para reflexão e crescimento pessoal.
                  </Text>
                </div>
              </View>
              <Text style={{ ...styles.text }}>
                A seguir, seus resultados são detalhadamente apresentados, guiando-o para ampliar seu autoconhecimento e
                desenvolvimento contínuo.
              </Text>
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[0].id}.{questions?.questions[0].text}
              </Text>
              <div style={styles.dashResult}>
                {responsesQuestions[0]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 1 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response == '0%' ? '#fcfcfc' : '#fdc300',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response == '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[0]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 0 && (
                        <div key={op.value} style={styles.dashResponseNeutral}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#A0AEC0',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[0]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 2 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#000000',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}
              </div>
              <LegendTextConsolidated qtyUsers={count} />
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[1].id}.{questions?.questions[1].text}
              </Text>
              <div style={styles.dashResult}>
                {responsesQuestions[1]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 1 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response == '0%' ? '#fcfcfc' : '#fdc300',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response == '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[1]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 0 && (
                        <div key={op.value} style={styles.dashResponseNeutral}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#A0AEC0',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[1]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 2 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#000000',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}
              </div>
              <LegendTextConsolidated qtyUsers={count} />
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[2].id}.{questions?.questions[2].text}
              </Text>
              <div style={styles.dashResult}>
                {responsesQuestions[2]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 1 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response == '0%' ? '#fcfcfc' : '#fdc300',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response == '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[2]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 0 && (
                        <div key={op.value} style={styles.dashResponseNeutral}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#A0AEC0',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[2]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 2 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#000000',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}
              </div>
              <LegendTextConsolidated qtyUsers={count} />
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[3].id}.{questions?.questions[3].text}
              </Text>
              <div style={styles.dashResult}>
                {responsesQuestions[3]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 1 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response == '0%' ? '#fcfcfc' : '#fdc300',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response == '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[3]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 0 && (
                        <div key={op.value} style={styles.dashResponseNeutral}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#A0AEC0',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[3]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 2 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#000000',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}
              </div>
              <LegendTextConsolidated qtyUsers={count} />
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[4].id}.{questions?.questions[4].text}
              </Text>
              <div style={styles.dashResult}>
                {responsesQuestions[4]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 1 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response == '0%' ? '#fcfcfc' : '#fdc300',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response == '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[4]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 0 && (
                        <div key={op.value} style={styles.dashResponseNeutral}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#A0AEC0',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[4]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 2 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#000000',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}
              </div>
              <LegendTextConsolidated qtyUsers={count} />
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[5].id}.{questions?.questions[5].text}
              </Text>
              <div style={styles.dashResult}>
                {responsesQuestions[5]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 1 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response == '0%' ? '#fcfcfc' : '#fdc300',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response == '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[5]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 0 && (
                        <div key={op.value} style={styles.dashResponseNeutral}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#A0AEC0',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[5]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 2 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#000000',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}
              </div>
              <LegendTextConsolidated qtyUsers={count} />
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[6].id}.{questions?.questions[6].text}
              </Text>
              <div style={styles.dashResult}>
                {responsesQuestions[6]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 1 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response == '0%' ? '#fcfcfc' : '#fdc300',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response == '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[6]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 0 && (
                        <div key={op.value} style={styles.dashResponseNeutral}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#A0AEC0',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[6]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 2 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#000000',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}
              </div>
              <LegendTextConsolidated qtyUsers={count} />
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[7].id}.{questions?.questions[7].text}
              </Text>
              <div style={styles.dashResult}>
                {responsesQuestions[7]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 1 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response == '0%' ? '#fcfcfc' : '#fdc300',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response == '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[7]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 0 && (
                        <div key={op.value} style={styles.dashResponseNeutral}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#A0AEC0',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[7]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 2 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#000000',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}
              </div>
              <LegendTextConsolidated qtyUsers={count} />
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[8].id}.{questions?.questions[8].text}
              </Text>
              <div style={styles.dashResult}>
                {responsesQuestions[8]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 1 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response == '0%' ? '#fcfcfc' : '#fdc300',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response == '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[8]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 0 && (
                        <div key={op.value} style={styles.dashResponseNeutral}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#A0AEC0',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[8]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 2 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#000000',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}
              </div>
              <LegendTextConsolidated qtyUsers={count} />
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[9].id}.{questions?.questions[9].text}
              </Text>
              <div style={styles.dashResult}>
                {responsesQuestions[9]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 1 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response == '0%' ? '#fcfcfc' : '#fdc300',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response == '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[9]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 0 && (
                        <div key={op.value} style={styles.dashResponseNeutral}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#A0AEC0',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}

                {responsesQuestions[9]?.map(
                  (
                    op: {
                      text: number | string
                      type: number | string
                      value: number | string
                      percent_response: number | string
                      qtt_response: number | string
                      selected_auto: number | string
                    },
                    index: number,
                  ) => (
                    <Fragment key={index}>
                      {op.type === 2 && (
                        <div key={op.value} style={styles.dashResponse}>
                          <div style={styles.dashResponseQuestion}>
                            <Text style={styles.dashResponseTexts}>{op.text}</Text>

                            {op.selected_auto == 'true' && (
                              <div>
                                <Image style={styles.image} src="/checksimbol.png" />
                              </div>
                            )}
                          </div>
                          <div style={styles.dashResponseChart}>
                            <div style={styles.dashFlexChar}>
                              <div
                                style={{
                                  backgroundColor: op.percent_response === '0%' ? '#ffffff' : '#000000',
                                  width: op.percent_response,
                                  minWidth: '6%',
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  paddingRight: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 9,
                                    textAlign: 'right',
                                    color: op.percent_response === '0%' ? '#000000' : '#fcfcfc',
                                    fontFamily: 'Lato Bold',
                                  }}
                                >
                                  {op.percent_response}
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ),
                )}
              </div>
              <LegendTextConsolidated qtyUsers={count} />
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[10].id}.{questions?.questions[10].text}
              </Text>
              <div>
                {questionsResponse[10]?.self?.map((r: string, index: number) => (
                  <Text key={index} style={styles.dashResponseOnlyTextsTitle}>
                    {r}
                  </Text>
                ))}
                {questionsResponse[10]?.evaluators?.map((r: string, index: number) => (
                  <Text key={index} style={styles.dashResponseOnlyTexts}>
                    {r}
                  </Text>
                ))}
              </div>
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[11].id}.{questions?.questions[11].text}
              </Text>
              <div>
                {questionsResponse[11]?.self?.map((r: string, index: number) => (
                  <Text key={index} style={styles.dashResponseOnlyTextsTitle}>
                    {r}
                  </Text>
                ))}
                {questionsResponse[11]?.evaluators?.map((r: string, index: number) => (
                  <Text key={index} style={styles.dashResponseOnlyTexts}>
                    {r}
                  </Text>
                ))}
              </div>
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[12].id}.{questions?.questions[12].text}
              </Text>
              <div>
                {questionsResponse[12]?.self?.map((r: string, index: number) => (
                  <Text key={index} style={styles.dashResponseOnlyTextsTitle}>
                    {r}
                  </Text>
                ))}
                {questionsResponse[12]?.evaluators?.map((r: string, index: number) => (
                  <Text key={index} style={styles.dashResponseOnlyTexts}>
                    {r}
                  </Text>
                ))}
              </div>
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>
                {questions?.questions[13].id}.{questions?.questions[13].text}
              </Text>
              <div>
                {questionsResponse[13]?.self?.map((r: string, index: number) => (
                  <Text key={index} style={styles.dashResponseOnlyTextsTitle}>
                    {r}
                  </Text>
                ))}
                {questionsResponse[13]?.evaluators?.map((r: string, index: number) => (
                  <Text key={index} style={styles.dashResponseOnlyTexts}>
                    {r}
                  </Text>
                ))}
              </div>
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.title}>RESULTADOS CONSOLIDADOS</Text>
              <Text style={{ ...styles.text }}>
                O gráfico consolidado da avaliação 360 graus proporciona uma visão ampla, destacando, por meio de barras
                amarelas, os pontos fortes, e, através de barras cinza escuro, os pontos de desenvolvimento. Os dados
                são apresentados tanto em percentuais — números que aparecem dentro de cada coluna — quanto em valores
                absolutos — números nos extremos das colunas (extremo superior refere-se aos pontos positivos, extremo
                inferior refere-se aos pontos a desenvolver).
              </Text>
              <Text style={{ ...styles.text }}>
                Os valores absolutos derivam do cálculo do total de respostas possíveis, que é resultado da
                multiplicação do número de respondentes pelo número de opções de resposta (6 positivas e 6 negativas).
                Por exemplo, com 25 respondentes e cada um com 6 opções de resposta, chega-se a um total de 150
                respostas possíveis (25x6). Se uma competência recebe 33 respostas positivas, isso equivale a 22% do
                total. O número absoluto, apresentado como 33/150 no extremo superior da coluna, indica que 33 respostas
                positivas foram dadas de um total de 150 possibilidades, oferecendo uma visão quantitativa clara do
                feedback recebido. Esse mesmo princípio se aplica aos pontos a desenvolver, indicados na parte inferior
                da coluna.
              </Text>
              <Text style={{ ...styles.text }}>
                Vale esclarecer que um valor absoluto é uma medida quantitativa que indica um número exato ou total, sem
                levar em consideração o contexto ou comparação com outros dados. Por exemplo, se um gráfico mostra que
                33 pessoas escolheram uma opção específica, esse número (33) é o valor absoluto. Um valor percentual,
                por outro lado, representa uma proporção ou fração de um todo, expressa em percentagem. Ele mostra a
                relação de uma parte em relação ao total, considerando o todo como 100%. Por exemplo, se 33 de 150
                respostas possíveis são positivas, o valor percentual dessas respostas positivas seria calculado como
                (33/150) * 100%, resultando em 22%.
              </Text>
              <Text style={{ ...styles.text }}>
                Para a análise do gráfico consolidado, você pode considerar os critérios abaixo, definidos após a
                observação de diversos relatórios:
              </Text>
              <Text style={{ ...styles.text, ...styles.bold }}>Análise dos Pontos Fortes (Amarelo):</Text>
              <View style={styles.list}>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>
                    0 a 20%: Resultado Ruim, indica que ninguém assinalou pontos positivos (0%) ou que até 20% de todos
                    os pontos positivos possíveis foram selecionados pelos avaliadores.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>21% a 40%: Resultado Mediano</Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>41% a 60%: Resultado Bom</Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>61% a 80%: Resultado Muito Bom</Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>Acima de 81%: Resultado Excelente</Text>
                </div>
              </View>

              <Text style={{ ...styles.text }}>
                Atingir 100% nos gráficos em amarelo indica unanimidade nas opções positivas, significando que todas as
                pessoas assinalaram todas as opções positivas possíveis. Em geral, competências com percentuais até 50%
                indicam áreas que precisam de atenção e possivelmente de desenvolvimento.
              </Text>
              <Text style={{ ...styles.text, ...styles.bold }}>Análise dos Pontos a Desenvolver (Cinza Escuro):</Text>
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <View style={styles.list}>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>
                    0 a 15%: Resultado Excelente, indica que ninguém assinalou pontos a desenvolver (0%) ou que até 15%
                    de todos os pontos de desenvolvimento possíveis foram selecionados pelos avaliadores.
                  </Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>16% a 30%: Resultado Bom</Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>31% a 50%: Resultado Mediano</Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>51% a 70%: Resultado Ruim</Text>
                </div>
                <div style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.listText}>Acima de 71%: Resultado Muito Ruim</Text>
                </div>
              </View>

              <Text style={{ ...styles.text }}>
                Atingir 100% nos gráficos em cinza escuro significa que todos os pontos a desenvolver foram unanimemente
                identificados pelos avaliadores. Este resultado destaca uma área de alta criticidade, necessitando de
                uma autoanálise profunda e de um plano de desenvolvimento imediato.
              </Text>
            </div>
          </View>
          <ReportFooter />
        </Page>
        <Page size="A4" wrap style={pageStyle.page}>
          <ReportHeader title={'Avaliação 360º'} />
          <View style={styles.content}>
            <div style={styles.container}>
              <Text style={styles.dashTitle}>Consolidado</Text>
            </div>

            <div style={{ ...styles.consolidContainer, height: '32%' }}>
              {questionsResponse?.map(
                (
                  op: {
                    positiveQty: number
                    negativeQty: number
                    neutralQty: number
                  },
                  index: number,
                ) =>
                  index < 5 && (
                    <div key={index} style={styles.consolidBoxItems}>
                      <Text style={styles.consolidTitleWithoutMargin}>{questions.consolidated[index]?.name}</Text>

                      <div style={styles.consolidBox}>
                        <div style={styles.consolidBoxSubTitle}>
                          <Text style={styles.consolidSubtitle}>
                            ({op.positiveQty}/{6 * count - op.neutralQty * 6})
                          </Text>
                        </div>

                        <div style={styles.consolidBoxContent}>
                          <div
                            style={{
                              backgroundColor: '#fdc300',
                              width: 50,
                              height: `${
                                parseInt(String((op.positiveQty / (6 * count - op.neutralQty * 6)) * 100 || 0)) + 10
                              }%`,
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Text style={styles.consolidChartText}>
                              <Fragment key={index}>
                                {parseInt(String((op.positiveQty / (6 * count - op.neutralQty * 6)) * 100 || 0))}%
                              </Fragment>
                            </Text>
                          </div>
                        </div>

                        <div style={styles.consolidBoxContentSecond}>
                          <div
                            style={{
                              backgroundColor: '#333333',
                              width: 50,
                              height: `${
                                parseInt(String((op.negativeQty / (6 * count - op.neutralQty * 6)) * 100 || 0)) + 5
                              }%`,
                              minHeight: '5%',
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Text style={styles.consolidChartText}>
                              <Fragment key={index}>
                                {parseInt(String((op.negativeQty / (6 * count - op.neutralQty * 6)) * 100 || 0))}%
                              </Fragment>
                            </Text>
                          </div>
                        </div>

                        <div style={styles.consolidBoxSubTitle}>
                          <Text style={styles.consolidSubtitleBottom}>
                            ({op.negativeQty}/{6 * count - op.neutralQty * 6})
                          </Text>
                        </div>
                      </div>
                    </div>
                  ),
              )}
            </div>

            <div style={{ ...styles.consolidContainer, height: '32%' }}>
              {questionsResponse?.map(
                (
                  op: {
                    positiveQty: number
                    negativeQty: number
                    neutralQty: number
                  },
                  index: number,
                ) =>
                  index >= 5 &&
                  index < 10 && (
                    <div key={index} style={styles.consolidBoxItems}>
                      <Text style={styles.consolidTitleWithoutMargin}>{questions.consolidated[index]?.name}</Text>

                      <div style={styles.consolidBox}>
                        <div style={styles.consolidBoxSubTitle}>
                          <Text style={styles.consolidSubtitle}>
                            ({op.positiveQty}/{6 * count - op.neutralQty * 6})
                          </Text>
                        </div>

                        <div style={styles.consolidBoxContent}>
                          <div
                            style={{
                              backgroundColor: '#fdc300',
                              width: 50,
                              height: `${
                                parseInt(String((op.positiveQty / (6 * count - op.neutralQty * 6)) * 100 || 0)) + 10
                              }%`,
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Text style={styles.consolidChartText}>
                              <Fragment key={index}>
                                {parseInt(String((op.positiveQty / (6 * count - op.neutralQty * 6)) * 100 || 0))}%
                              </Fragment>
                            </Text>
                          </div>
                        </div>

                        <div style={styles.consolidBoxContentSecond}>
                          <div
                            style={{
                              backgroundColor: '#333333',
                              width: 50,
                              height: `${
                                parseInt(String((op.negativeQty / (6 * count - op.neutralQty * 6)) * 100 || 0)) + 5
                              }%`,
                              minHeight: '5%',
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Text style={styles.consolidChartText}>
                              <Fragment key={index}>
                                {parseInt(String((op.negativeQty / (6 * count - op.neutralQty * 6)) * 100 || 0))}%
                              </Fragment>
                            </Text>
                          </div>
                        </div>

                        <div style={styles.consolidBoxSubTitle}>
                          <Text style={styles.consolidSubtitleBottom}>
                            ({op.negativeQty}/{6 * count - op.neutralQty * 6})
                          </Text>
                        </div>
                      </div>
                    </div>
                  ),
              )}
            </div>

            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <Text
                style={{
                  ...styles.legendCircle,
                  backgroundColor: '#fdc300',
                }}
              />
              <Text style={styles.legendText}>Pontos fortes</Text>
              <Text
                style={{
                  ...styles.legendCircle,
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                }}
              />
              <Text style={styles.legendText}>pontos a desenvolver</Text>
              <Text style={styles.legendSubtitle}>{`Número de respostas: ${count}`}</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 20,
                marginHorizontal: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Text
                style={{
                  marginHorizontal: 'auto',
                  fontSize: 12,
                  fontFamily: 'Lato Bold',
                  fontWeight: 'bold',
                }}
              >
                Consolidado 360 graus
              </Text>
              <Text
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 10,
                  marginHorizontal: 'auto',
                  maxWidth: 310,
                  fontSize: 9,
                }}
              >
                Barras amarelas representam pontos fortes, enquanto as cinza escuro indicam pontos a desenvolver. Os
                percentuais dentro das colunas e os valores absolutos nos extremos das barras refletem o grau de
                concordância entre os avaliadores, com base no total de respostas possíveis (seis positivas e seis
                negativas por avaliador).
              </Text>
            </View>
          </View>
          <ReportFooter />
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default DocumentComponent
