import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Tab,
  Table,
  TabList,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { OpenQuestion } from 'components/Dashboards/OpenQuestions'
import LoadingAlert from 'components/Loading/LoadingAlert'
import { SimpleDashboard } from 'layouts/dash/types'
import openQuestions from 'pages/Questions/Area/QuestionMapaTatico/openQuestions.json'
import questions from 'pages/Questions/Area/QuestionMapaTatico/questions.json'
import React, { useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import api from 'services/api'

interface IProps {
  sector: { value: string }[]
  chartRefs: React.MutableRefObject<any>[]
  printing: boolean
  responses: React.MutableRefObject<any>
}

export interface IOpData {
  likert: number[]
  responses: string[]
}

const OperacionalColor = {
  totallyAgree: '#FFC900',
  agree: '#C26700',
  middle: '#0A0C0B',
  disagree: '#575C55',
  totallyDisagree: '#7F8D8C',
}

function TaticoChart(props: SimpleDashboard): JSX.Element {
  const sector = props.userId
  const [page, setPage] = useState(0)
  const [opData, setOpData] = useState<IOpData[][]>()
  const [evaluators, setEvaluators] = useState<{ name: string }[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const toast = useToast()

  const conData = useMemo(() => {
    if (!opData || !opData.length) return []
    const half = (opData?.[0][0].likert.reduce((acc, cur) => acc + cur, 0) || 2) / 2

    const opDataConsolidated = opData.map((page, p) =>
      page.map((question, q) => {
        if (!(question.likert[0] + question.likert[1] > half)) return null
        if (!questions?.[p]?.[q]) return null
        return { ...question, text: questions?.[p]?.[q]?.text }
      }),
    )

    return opDataConsolidated.reduce((acc, curr) => [...acc, ...curr], []).filter((q) => !!q)
  }, [opData])

  useEffect(() => {
    setPage(0)
    if (!sector.length) {
      // responses.current = [[], [], [], []]
      setOpData(undefined)
      setEvaluators([])
      return
    }
    const fetchTableRes = async () => {
      setLoading(true)

      try {
        const { data } = await api.get(`/tableresponse/taticoArea/${sector}`)
        setOpData(data.pages)
        setEvaluators(data.evaluators)
        // chartRefs[5].current = data.pages[4].map((d: any) => d.responses)
        // responses.current = data.pages
      } catch (err) {
        toast({
          status: 'error',
          title: 'Um erro ocorreu ao procurar os dados',
        })
      }
      setLoading(false)
    }

    fetchTableRes()
  }, [sector])

  return (
    <Flex w="100%" flexDirection="column" paddingLeft="0.625rem" overflow="auto" style={{ scrollbarWidth: 'thin' }}>
      <LoadingAlert loading={loading} />

      {!loading && (!opData || !evaluators.length) ? (
        <Flex
          bgColor={'gray.200'}
          padding="1.25rem"
          borderRadius={'0.3125rem'}
          justifyContent="center"
          alignItems={'center'}
          width="12.5rem"
          marginLeft="1.25rem"
          marginTop="1.25rem"
        >
          <Text>Não há respostas</Text>
        </Flex>
      ) : null}

      {sector && opData ? (
        <Flex gap="0.3125rem">
          <Tabs w="100%" isFitted colorScheme="yellow">
            <TabList>
              <Tab onClick={() => setPage(0)}>Clareza</Tab>
              <Tab onClick={() => setPage(1)}>Eficiência</Tab>
              <Tab onClick={() => setPage(2)}>Equipe</Tab>
              <Tab onClick={() => setPage(3)}>Liderança</Tab>
              <Tab onClick={() => setPage(4)}>Respostas</Tab>
              <Tab onClick={() => setPage(5)}>Consolidado</Tab>
              <Tab onClick={() => setPage(6)}>Considerações</Tab>
            </TabList>
          </Tabs>
        </Flex>
      ) : null}

      {opData && [0, 1, 2, 3, 5].includes(page) ? (
        <Flex justifyContent="space-between" alignItems="center" mt={3} mb={3}>
          <Flex alignItems="center" m="0.3125rem">
            <Text bg={OperacionalColor.totallyAgree} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
            <Text fontSize="0.6rem" mr="0.625rem">
              Concordo Totalmente
            </Text>
            <Text bg={OperacionalColor.agree} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
            <Text fontSize="0.6rem" mr="0.625rem">
              Concordo
            </Text>
            <Text bg={OperacionalColor.middle} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
            <Text fontSize="0.6rem" mr="0.625rem">
              Não concordo nem discordo
            </Text>
            <Text bg={OperacionalColor.disagree} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
            <Text fontSize="0.6rem" mr="0.625rem">
              Discordo
            </Text>
            <Text bg={OperacionalColor.totallyDisagree} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
            <Text fontSize="0.6rem" mr="0.625rem">
              Discordo totalmente
            </Text>
          </Flex>
          <Box>
            <Popover trigger="hover">
              <PopoverTrigger>
                <Text fontSize="0.8rem" mr="0.625rem" fontWeight="bold" cursor="pointer">
                  {evaluators.length} respostas
                </Text>
              </PopoverTrigger>
              <PopoverContent overflow={'scroll'} maxH={'31.25rem'}>
                <PopoverArrow />
                <PopoverHeader>Respondentes</PopoverHeader>
                <PopoverBody padding={0} overflow={'scroll'}>
                  <Box>
                    {evaluators.map(({ name }, i) => (
                      <Text padding={2} key={i} width={'100%'} backgroundColor={i % 2 ? 'initial' : 'gray.100'}>
                        {name}
                      </Text>
                    ))}
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </Flex>
      ) : null}

      {opData && [0, 1, 2, 3].includes(page) ? (
        <Flex
          justifyContent="space-evenly"
          marginTop={'1.25rem'}
          marginBottom="2.5rem"
          paddingTop="0.3125rem"
          paddingBottom="0.3125rem"
        >
          <Flex maxWidth="37.5rem" width="100%" height="18.75rem" flexDirection="column">
            <Bar
              data={{
                labels: questions[page].map((q, index) =>
                  q.text.match(/(.{1,35})(?:\s|$)/g)?.map((chunk) => chunk.trim()),
                ),
                datasets: [
                  {
                    label: 'Concordo Totalmente',
                    data: opData[page].map((q) => q.likert[4]),
                    backgroundColor: OperacionalColor.totallyAgree,
                    barThickness: 40,
                  },
                  {
                    label: 'Concordo',
                    data: opData[page].map((q) => q.likert[3]),
                    backgroundColor: OperacionalColor.agree,
                    barThickness: 40,
                  },
                  {
                    label: 'Não concordo nem discordo',
                    data: opData[page].map((q) => q.likert[2]),
                    backgroundColor: OperacionalColor.middle,
                    barThickness: 40,
                  },
                  {
                    label: 'Discordo',
                    data: opData[page].map((q) => q.likert[1]),
                    backgroundColor: OperacionalColor.disagree,
                    barThickness: 40,
                  },
                  {
                    label: 'Discordo totalmente',
                    data: opData[page].map((q) => q.likert[0]),
                    backgroundColor: OperacionalColor.totallyDisagree,
                    barThickness: 40,
                  },
                ],
              }}
              options={{
                animation: {
                  duration: 0,
                },
                responsive: true,
                indexAxis: 'y',
                layout: {
                  padding: {
                    top: 40,
                  },
                },
                maintainAspectRatio: false,
                plugins: {
                  tooltip: {
                    position: 'myCustomPositioner',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    callbacks: {
                      title: () => '',
                      label: (e) => {
                        return `${e.dataset.label}: ${e.raw} (${((Number(e.raw) * 100) / evaluators.length)
                          .toFixed(1)
                          .replaceAll('.', ',')}%)`
                      },
                    },
                  },
                  legend: {
                    display: false,
                    position: 'bottom',
                  },
                  datalabels: {
                    labels: {
                      value: {
                        formatter: (value: number) => {
                          const percent = ((value / evaluators.length) * 100).toFixed(1)
                          if (Number(percent) < 10) return ''
                          const formatNumber = percent.split('.')
                          if (Number(formatNumber[1]) >= 1) return `${formatNumber.join(',')}%`
                          return `${formatNumber[0]}%`
                        },
                        color: 'white',
                        font: {
                          weight: 700,
                          size: 14,
                        },
                      },
                    },
                  },
                },
                scales: {
                  y: {
                    stacked: true,
                    grid: {
                      display: false,
                    },
                    ticks: {
                      crossAlign: 'far',
                    },
                  },
                  x: {
                    stacked: true,
                    grid: {
                      display: false,
                      borderWidth: 0,
                    },
                    title: {
                      display: false,
                    },
                    ticks: {
                      display: false,
                      stepSize: 1,
                    },
                  },
                },
              }}
            />
            <Text fontWeight="800" align="center" marginTop="0.625rem" fontSize="1.25rem">
              Mapeamento Tático da Área
            </Text>
            <Text
              as="i"
              fontWeight="400"
              align="center"
              marginTop="0.625rem"
              fontSize="0.875rem"
              maxWidth="700"
              color="gray.500"
            >
              Valores em porcentagem de acordo com a escala Likert, com opções que variam de &apos;discordo
              totalmente&apos; até &apos;concordo totalmente&apos;, indicando as escolhas feitas pelos colaboradores.
            </Text>
          </Flex>
          {opData[page].filter((q) => q.responses.length > 0).length > 0 ? (
            <Box
              boxShadow={'#7a7a7a 0rem 0rem 0.0625rem'}
              maxWidth="25rem"
              borderRadius={'0.3125rem'}
              overflowY="auto"
              height="100%"
              maxHeight="37.5rem"
              css={{
                '&::-webkit-scrollbar': {
                  width: '0.25rem',
                  backgroundColor: 'rgba(155, 155, 155, 0.2)',
                },
                '&::-webkit-scrollbar-track': {
                  width: '0.375rem',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'rgba(155, 155, 155, 0.5)',
                  borderRadius: '1.5rem',
                },
              }}
            >
              <Table style={{ scrollbarWidth: 'thin' }}>
                <Thead position="sticky" top="0">
                  <Tr bgColor={'white'}>
                    <Th>Respostas</Th>
                  </Tr>
                </Thead>
                <Tbody style={{ scrollbarWidth: 'thin' }}>
                  {opData[page].map((q, index) => {
                    const { responses } = q
                    if (!responses.length) return null
                    return (
                      <React.Fragment key={index}>
                        <Tr>
                          <Td backgroundColor={'#edf2f7'} fontWeight="700" color="gray.600">
                            <Text>{questions[page][index].text}</Text>
                            <Text as="i" fontSize={12}>
                              {questions[page][index].completeIfBiggerThan &&
                                'Para quem concordou parcial ou totalmente:'}
                              {questions[page][index].completeIfSmallerThan &&
                                'Para quem discordou parcial ou totalmente:'}
                            </Text>
                            <Text>{questions[page][index].completeText}</Text>
                          </Td>
                        </Tr>
                        {responses.map((response, responseIndex) => (
                          <Tr key={responseIndex}>
                            <Td>{`${responseIndex + 1}. ${response}`}</Td>
                          </Tr>
                        ))}
                      </React.Fragment>
                    )
                  })}
                </Tbody>
              </Table>
            </Box>
          ) : null}
        </Flex>
      ) : null}

      {opData && (page === 4 || page == 6) ? (
        <Flex
          wrap="wrap"
          gap="1.25rem"
          paddingTop="1.25rem"
          paddingBottom="1.25rem"
          overflowY={'scroll'}
          paddingLeft={'0.1875rem'}
          css={{
            '&::-webkit-scrollbar': {
              width: '0.25rem',
              backgroundColor: 'rgba(155, 155, 155, 0.2)',
            },
            '&::-webkit-scrollbar-track': {
              width: '0.375rem',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(155, 155, 155, 0.5)',
              borderRadius: '1.5rem',
            },
          }}
        >
          {openQuestions.map((question, index) => {
            if (page === 4 && index < 2) {
              return (
                <OpenQuestion
                  width={{ xl: '48%', md: '48%', base: '95%' }}
                  key={question.question}
                  title={question.text}
                  responses={opData[4]?.[index].responses || []}
                />
              )
            } else if (page === 6 && index > 1) {
              return (
                <OpenQuestion
                  width={{ xl: '95%', md: '95%', base: '95%' }}
                  key={question.question}
                  title={question.text}
                  responses={opData[4]?.[index].responses || []}
                />
              )
            }
          })}
        </Flex>
      ) : null}

      {conData && page === 5 ? (
        <Box
          overflowY={'scroll'}
          height={`${60 * conData.length + 50 + 120}px`}
          css={{
            '&::-webkit-scrollbar': {
              width: '0.25rem',
              backgroundColor: 'rgba(155, 155, 155, 0.2)',
            },
            '&::-webkit-scrollbar-track': {
              width: '0.375rem',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(155, 155, 155, 0.5)',
              borderRadius: '1.5rem',
            },
          }}
        >
          <Flex flexDirection="column" height={`${60 * conData.length + 50}px`} width="50rem" margin="auto">
            <Bar
              data={{
                labels: conData.map((q) => q?.text.match(/(.{1,55})(?:\s|$)/g)?.map((chunk) => chunk.trim())),
                datasets: [
                  {
                    label: 'Concordo Totalmente',
                    data: conData.map((q) => q?.likert[4]),
                    backgroundColor: OperacionalColor.totallyAgree,
                  },
                  {
                    label: 'Concordo',
                    data: conData.map((q) => q?.likert[3]),
                    backgroundColor: OperacionalColor.agree,
                  },
                  {
                    label: 'Não concordo nem discordo',
                    data: conData.map((q) => q?.likert[2]),
                    backgroundColor: OperacionalColor.middle,
                  },
                  {
                    label: 'Discordo',
                    data: conData.map((q) => q?.likert[1]),
                    backgroundColor: OperacionalColor.disagree,
                  },
                  {
                    label: 'Discordo totalmente',
                    data: conData.map((q) => q?.likert[0]),
                    backgroundColor: OperacionalColor.totallyDisagree,
                  },
                ],
              }}
              options={{
                animation: {
                  duration: 0,
                },
                responsive: true,
                indexAxis: 'y',
                layout: {
                  padding: {
                    top: 40,
                  },
                },
                maintainAspectRatio: false,
                plugins: {
                  tooltip: {
                    xAlign: 'center',
                    yAlign: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    callbacks: {
                      title: () => '',
                    },
                  },
                  title: {
                    display: false,
                  },
                  legend: {
                    display: false,
                    position: 'bottom',
                  },
                  datalabels: {
                    labels: {
                      value: {
                        formatter: (value: number) => {
                          const percent = ((value / evaluators.length) * 100).toFixed(1)
                          if (Number(percent) < 10) return ''
                          const formatNumber = percent.split('.')
                          if (Number(formatNumber[1]) >= 1) return `${formatNumber.join(',')}%`
                          return `${formatNumber[0]}%`
                        },
                        color: 'white',
                        font: {
                          weight: 700,
                          size: 16,
                        },
                      },
                    },
                  },
                },
                scales: {
                  y: {
                    stacked: true,
                    grid: {
                      display: false,
                    },
                    ticks: {
                      crossAlign: 'far',
                    },
                  },
                  x: {
                    stacked: true,

                    grid: {
                      display: false,
                      borderWidth: 0,
                    },
                    title: {
                      display: false,
                    },
                    ticks: {
                      display: false,
                      stepSize: 1,
                    },
                  },
                },
              }}
            />
            <Text fontWeight="800" align="center" marginTop="0.625rem" fontSize="1.25rem">
              Consolidado Mapa Tático da Área
            </Text>
            <Text
              as="i"
              fontWeight="400"
              align="center"
              marginTop="0.625rem"
              fontSize="0.875rem"
              maxWidth="700"
              color="gray.500"
            >
              Gráfico consolidado dos principais gaps identificados no Mapa Tático da Área. Apresenta as respostas
              negativas, considerando apenas aqueles que responderam &apos;discordo parcialmente&apos; e &apos;discordo
              totalmente&apos;, com pontuação igual ou maior que 50%
            </Text>
          </Flex>
        </Box>
      ) : null}

      {/* {conData && printing ? (
        <Box
          overflowY={'scroll'}
          css={{
            '&::-webkit-scrollbar': {
              width: '0.25rem',
              backgroundColor: 'rgba(155, 155, 155, 0.2)'
            },
            '&::-webkit-scrollbar-track': {
              width: '0.375rem'
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(155, 155, 155, 0.5)',
              borderRadius: '1.5rem'
            }
          }}
          margin="auto"
        >
          <Flex
            flexDirection="column"
            height={`${60 * conData.length + 50}px`}
            width="50rem"
            ref={chartRefs[4]}
          >
            <Bar
              data={{
                labels: conData.map((q) =>
                  q?.text
                    .match(/(.{1,50})(?:\s|$)/g)
                    ?.map((chunk) => chunk.trim())
                ),
                datasets: [
                  {
                    label: 'Concordo Totalmente',
                    data: conData.map((q) => q?.likert[4]),
                    backgroundColor: OperacionalColor.totallyAgree
                  },
                  {
                    label: 'Concordo',
                    data: conData.map((q) => q?.likert[3]),
                    backgroundColor: OperacionalColor.agree
                  },
                  {
                    label: 'Não concordo nem discordo',
                    data: conData.map((q) => q?.likert[2]),
                    backgroundColor: OperacionalColor.middle
                  },
                  {
                    label: 'Discordo',
                    data: conData.map((q) => q?.likert[1]),
                    backgroundColor: OperacionalColor.disagree
                  },
                  {
                    label: 'Discordo totalmente',
                    data: conData.map((q) => q?.likert[0]),
                    backgroundColor: OperacionalColor.totallyDisagree
                  }
                ]
              }}
              options={{
                animation: {
                  duration: 0
                },
                responsive: true,
                indexAxis: 'y',
                layout: {
                  padding: {
                    top: 40
                  }
                },
                maintainAspectRatio: false,
                plugins: {
                  tooltip: {
                    xAlign: 'center',
                    yAlign: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    callbacks: {
                      title: () => ''
                    }
                  },
                  title: {
                    display: false
                  },
                  legend: {
                    display: false,
                    position: 'bottom'
                  },
                  datalabels: {
                    labels: {
                      value: {
                        formatter: (value: number) => {
                          const percent = (
                            (value / evaluators.length) *
                            100
                          ).toFixed(1)
                          if (Number(percent) < 10) return ''
                          const formatNumber = percent.split('.')
                          if (Number(formatNumber[1]) >= 1)
                            return `${formatNumber.join(',')}%`
                          return `${formatNumber[0]}%`
                        },
                        color: 'white',
                        font: {
                          weight: 700,
                          size: 16
                        }
                      }
                    }
                  }
                },
                scales: {
                  y: {
                    stacked: true,
                    grid: {
                      display: false
                    },
                    ticks: {
                      color: 'black',
                      font: { weight: '600', size: 14 },
                      crossAlign: 'far'
                    }
                  },
                  x: {
                    stacked: true,

                    grid: {
                      display: false,
                      borderWidth: 0
                    },
                    title: {
                      display: false
                    },
                    ticks: {
                      display: false,
                      stepSize: 1
                    }
                  }
                }
              }}
            />
          </Flex>
        </Box>
      ) : null}

      {opData && printing
        ? opData.map((p, index) => {
            if (index > 3) return null
            return (
              <Flex
                maxWidth="56.25rem"
                width="100%"
                height="34.375rem"
                flexDirection="column"
                key={index}
                ref={chartRefs[index]}
              >
                <Bar
                  data={{
                    labels: questions[index].map((q, index) => {
                      return q.text
                        .match(/(.{1,35})(?:\s|$)/g)
                        ?.map((chunk) => chunk.trim())
                    }),
                    datasets: [
                      {
                        label: 'Concordo Totalmente',
                        data: p.map((q) => q.likert[4]),
                        backgroundColor: OperacionalColor.totallyAgree
                      },
                      {
                        label: 'Concordo',
                        data: p.map((q) => q.likert[3]),
                        backgroundColor: OperacionalColor.agree
                      },
                      {
                        label: 'Não concordo nem discordo',
                        data: p.map((q) => q.likert[2]),
                        backgroundColor: OperacionalColor.middle
                      },
                      {
                        label: 'Discordo',
                        data: p.map((q) => q.likert[1]),
                        backgroundColor: OperacionalColor.disagree
                      },
                      {
                        label: 'Discordo totalmente',
                        data: p.map((q) => q.likert[0]),
                        backgroundColor: OperacionalColor.totallyDisagree
                      }
                    ]
                  }}
                  options={{
                    animation: {
                      duration: 0
                    },
                    responsive: true,
                    indexAxis: 'y',
                    layout: {
                      padding: {
                        top: 40
                      }
                    },
                    maintainAspectRatio: false,
                    plugins: {
                      tooltip: {
                        xAlign: 'center',
                        yAlign: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        callbacks: {
                          title: () => ''
                        }
                      },
                      legend: {
                        display: false
                      },
                      datalabels: {
                        labels: {
                          value: {
                            formatter: (value: number) => {
                              const percent = (
                                (value / evaluators.length) *
                                100
                              ).toFixed(1)
                              if (Number(percent) < 10) return ''
                              const formatNumber = percent.split('.')
                              if (Number(formatNumber[1]) >= 1)
                                return `${formatNumber.join(',')}%`
                              return `${formatNumber[0]}%`
                            },
                            color: 'white',
                            font: {
                              weight: 700,
                              size: 18
                            }
                          }
                        }
                      }
                    },
                    scales: {
                      y: {
                        stacked: true,
                        grid: {
                          display: false
                        },
                        ticks: {
                          color: 'black',
                          font: { weight: '600', size: 17 },
                          crossAlign: 'far'
                        }
                      },
                      x: {
                        stacked: true,
                        grid: {
                          display: false,
                          borderWidth: 0
                        },
                        title: {
                          display: false
                        },
                        ticks: {
                          display: false,
                          stepSize: 1
                        }
                      }
                    }
                  }}
                />
              </Flex>
            )
          })
        : null} */}
    </Flex>
  )
}

export default TaticoChart
