import { BrowserRouter, Switch } from 'react-router-dom'
import LoginEmail from '../pages/LoginEmail'
import Route from '../components/Route/Route'

import Testing from 'pages/Testing/AppTesting'

import CompleteEvaluation from 'pages/Evaluation/CompleteEvaluation'
import DoneEvaluation from 'pages/Evaluation/DoneEvaluation'
import ExpiredQuestion from 'pages/Evaluation/ExpiredEvaluation'
import InvalidEvaluation from 'pages/Evaluation/InvalidEvaluation'
import { Interview360 } from 'pages/Interviews/Interview360'
import QuestionAlpha from 'pages/Questions/Scanner/QuestionAlpha'
import QuestionAuto360New from 'pages/Questions/360/QuestionAuto360New'
import NewQuestionDesempenhoDeEquipe from 'pages/Questions/Area/QuestionDesempenhoDeEquipe/NewQuestionDesempenhoDeEquipe'
import QuestionMapaOperacionalDaArea from 'pages/Questions/Area/QuestionMapaOperacionalDaArea'
import QuestionMapaTatico from 'pages/Questions/Area/QuestionMapaTatico'
import QuestionMotivacional from 'pages/Questions/Scanner/QuestionMotivacionalObjetivos'
import QuestionTemperamentos from 'pages/Questions/Scanner/QuestionTemperamentos'
import QuestionTratamento from 'pages/Questions/Scanner/QuestionTratamentoDeObjecoes'
import QuestionVia from 'pages/Questions/Scanner/QuestionVIA'
import Evaluations from 'pages/User/Evaluations'
import Scanner from 'pages/User/Scanner'
import QuestionAnaliseCritica from 'pages/Questions/Others/QuestionAnaliseCritica'
import Question360 from 'pages/Questions/360/Question360'

import Admin from 'layouts/Admin'
import Company from 'pages/Company'
import Courses from 'pages/Course/Courses'
import Course from 'pages/Course/UserCourses'
import Dash from 'pages/Dashboards'
import GestaoDeDados from 'pages/GestaoDeDados'
import Home from 'pages/Home'
import Profile from 'pages/Profile'
import Queues from 'pages/Queues'
import User from 'pages/Users'
import Avaliacao360PorEntrevista from 'pages/Dashboards/360/Entrevista360'
import QuestionEstresse from 'pages/Questions/Others/QuestionEstresse'
import Register from 'pages/Company/Register'

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={LoginEmail} />

        {/* Evaluation */}
        <Route path="/evaluation/expired/:time" exact component={ExpiredQuestion} />
        <Route path="/evaluation/done/:id" exact component={DoneEvaluation} />
        <Route path="/evaluation/invalid/:id" exact component={InvalidEvaluation} />
        <Route path="/evaluation/complete/:id" exact component={CompleteEvaluation} />
        <Route path="/evaluations/:user_id/" exact component={Evaluations} />

        {/* Scanner */}
        <Route path="/scanner/:id" exact component={Scanner} />
        <Route path="/testealphanew/:id" exact component={QuestionAlpha} />
        <Route path="/temperamentosnew/:id" exact component={QuestionTemperamentos} />
        <Route path="/tratamentodeobjecoesnew/:id" exact component={QuestionTratamento} />
        <Route path="/motivacionalobjetivosnew/:id" exact component={QuestionMotivacional} />
        <Route path="/inqueritovianew/:id" exact component={QuestionVia} />

        {/* Area */}
        <Route path="/desempenhodeequipenew/:id" exact component={NewQuestionDesempenhoDeEquipe} />
        <Route path="/mapaoperacionaldaareanew/:id" exact component={QuestionMapaOperacionalDaArea} />
        <Route path="/mapataticonew/:id" exact component={QuestionMapaTatico} />

        {/* 360 */}
        <Route path="/interview360/:evaluation" component={Interview360} isPrivate />
        <Route path="/question360new/:id" exact component={Question360} />
        <Route path="/question360autonew/:id" exact component={QuestionAuto360New} />

        {/* Others */}
        <Route path="/analisecritica/:id" exact component={QuestionAnaliseCritica} />
        <Route path="/estresse/:id" exact component={QuestionEstresse} />

        <Route path="/company/register/:transaction" component={Register} />
        <Admin>
          <Route path="/home" component={Home} isPrivate />
          <Route path="/profile" component={Profile} isPrivate />
          <Route path="/dash/:id" component={Dash} isPrivate />
          <Route path="/company" component={Company} isPrivate />
          <Route path="/queues" component={Queues} isPrivate />
          <Switch>
            <Route path="/users/:id" component={User} isPrivate />
            <Route path="/users" component={User} isPrivate />
          </Switch>
          <Route path="/gestaodados" component={GestaoDeDados} isPrivate />
          <Route path="/courses" component={Courses} isPrivate />
          <Route path="/course/:id" component={Course} isPrivate />
          <Route path="/avaliacao360porentrevista" component={Avaliacao360PorEntrevista} isPrivate />
        </Admin>

        {/* Testing */}
        <Route path="/testing" component={Testing} isPrivate />
      </Switch>
    </BrowserRouter>
  )
}
