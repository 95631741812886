import { FormControl } from '@chakra-ui/react'
import React, { useEffect, useMemo } from 'react'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { MultiSelect } from 'react-multi-select-component'
import { toast } from 'react-toastify'
import api from 'services/api'
import { IOption, IUseEvaluationSelectorStoreCourse, useEvaluationSelectorStore } from 'stores/evaluationSelector'

export default function CourseSelector() {
  const { loading, setLoading, courses, selectedCourses, setCourses, setSelectedCourses } = useEvaluationSelectorStore()

  const options = useMemo(() => {
    return courses.map((course) => ({
      label: course.name,
      value: course.id,
    }))
  }, [courses])

  const selectedOptions = useMemo(() => {
    return selectedCourses.map((course) => ({
      label: course.name,
      value: course.id,
    }))
  }, [selectedCourses])

  useEffect(() => {
    setLoading(true)

    api
      .get<IUseEvaluationSelectorStoreCourse[]>('courses')
      .then(({ data }) => {
        const sorted = data.sort((a, b) => a.name.localeCompare(b.name))

        if (data.length) return setCourses(sorted)

        return setCourses([{ name: 'Sem registros', id: '0' }])
      })
      .catch(() => toast.error('Ocorreu um erro ao buscar os treinamentos.'))
      .finally(() => setLoading(false))
  }, [])

  return (
    <FormControl width="18.75rem">
      <MultiSelect
        disabled={loading}
        isLoading={loading}
        options={options}
        hasSelectAll={false}
        value={selectedOptions}
        onChange={(e: IOption[]) => {
          const filtered = e
            .map((a) => courses.find((b) => b.id === a.value))
            .filter((course) => course !== undefined) as IUseEvaluationSelectorStoreCourse[]

          setSelectedCourses(filtered)
        }}
        labelledBy="Select"
        overrideStrings={{
          allItemsAreSelected: 'Todos Selecionados',
          clearSearch: 'Limpar',
          clearSelected: 'Limpar',
          noOptions: 'Sem opções',
          search: 'Procurar',
          selectAll: 'Selecionar Todos',
          selectAllFiltered: 'Selecionar Todos (Filtrado)',
          selectSomeItems: 'Treinamentos',
          create: 'Criar',
        }}
        ArrowRenderer={() => <RiArrowDropDownLine size="1.25rem" />}
      />
    </FormControl>
  )
}
