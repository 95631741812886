import { FormControl, FormLabel } from '@chakra-ui/react'
import React, { useEffect, useState, useMemo } from 'react'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { MultiSelect } from 'react-multi-select-component'
import { toast } from 'react-toastify'
import api from 'services/api'
import { IOption, IUseEvaluationSelectorStoreSector, useEvaluationSelectorStore } from 'stores/evaluationSelector'

export default function SectorSelector() {
  const { loading, setLoading, sectors, selectedSectors, setSectors, setSelectedSectors, selectedCompanies } =
    useEvaluationSelectorStore()

  const options = useMemo(
    () =>
      sectors.map((sec) => ({
        label: `${sec.sector_name}${sectors.length > 1 ? ` (${sec.commercial_name.trim()})` : ''} `,
        value: sec.id,
      })),
    [sectors],
  )

  const selectedOptions = useMemo(
    () =>
      selectedSectors.map((sector) => ({
        label: sector.sector_name,
        value: sector.id,
      })),
    [selectedSectors],
  )

  useEffect(() => {
    if (!selectedCompanies?.length) {
      setSectors([])
      setSelectedSectors([])
      return
    }
    setLoading(true)
    api
      .post(`sectors/different-companies/`, {
        company_ids: selectedCompanies.map((company) => company.id),
      })
      .then(({ data }) => setSectors(data))
      .catch(() => toast.error('Ocorreu um erro ao buscar os setores.'))
      .finally(() => setLoading(false))
  }, [selectedCompanies])

  return (
    <FormControl w="18.75rem">
      <FormLabel fontSize="0.75rem">Setores</FormLabel>
      <MultiSelect
        disabled={loading}
        isLoading={loading}
        options={options}
        value={selectedOptions}
        onChange={(e: IOption[]) => {
          const filtered = e
            .map((a) => sectors.find((b) => b.id === a.value))
            .filter((sector) => sector !== undefined) as IUseEvaluationSelectorStoreSector[]

          setSelectedSectors(filtered)
        }}
        labelledBy="Select"
        overrideStrings={{
          allItemsAreSelected: 'Todos Selecionados',
          clearSearch: 'Limpar',
          clearSelected: 'Limpar',
          noOptions: 'Sem opções',
          search: 'Procurar',
          selectAll: 'Selecionar Todos',
          selectAllFiltered: 'Selecionar Todos (Filtrado)',
          selectSomeItems: 'Setores',
          create: 'Criar',
        }}
        ArrowRenderer={() => <RiArrowDropDownLine size="1.25rem" />}
      />
    </FormControl>
  )
}
