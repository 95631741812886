import { Button, Divider, Flex, Link, Text, useDisclosure } from '@chakra-ui/react'

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import api from 'services/api'
import { useAuthStore } from 'stores/authStore'
import { useLoadingStore } from 'stores/loadingStore'
import { ISector, ISelectedUser, IUser } from './types'
import { UserEditModal } from './UserEditModal'
import { UserEvaluateModal } from './UserEvaluateModal'
import UserGrid from './UserGrid'
import { UserModal } from './UserModal'

const User = () => {
  const { user } = useAuthStore()

  const { id } = useParams<{ id: string }>()

  const [sectors, setSectors] = useState<ISector[]>([])
  const [users, setUsers] = useState<IUser[]>([])
  const [selectedUsers, setSelectedUsers] = useState<ISelectedUser[]>([])
  const [editUser, setEditUser] = useState<IUser | undefined>(undefined)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { setLoading } = useLoadingStore()

  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure()
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure()

  async function getUsers(id: string): Promise<void> {
    setLoading(true)
    if (id === '') {
      setUsers([])
      setLoading(false)
      return
    }
    try {
      const { data } = await api.get<IUser[]>(`users/list/${id}`)
      setUsers(data)
    } catch (err) {
      setUsers([])
    }
    setLoading(false)
  }

  async function getSectors(id: string): Promise<void> {
    if (id === '') {
      setSectors([])
      return
    }
    try {
      const { data } = await api.get(`sectors/list/${id}`)
      setSectors(data)
    } catch (err) {
      setSectors([])
    }
  }

  useEffect(() => {
    if (user.type_account === 2) {
      setSelectedUsers([])
      getUsers(user.company_id as string)
      getSectors(user.company_id as string)
    } else if (id) {
      setSelectedUsers([])
      getUsers(id)
      getSectors(id)
    }
  }, [])

  return (
    <Flex alignItems="center" flexDirection="column" width="100%" paddingX="5%" height="calc(100vh - 4rem)">
      {isOpen && (
        <UserModal
          isOpen={isOpen}
          onClose={onClose}
          companyId={id}
          users={users}
          getUsers={() => getUsers(id ?? (user.company_id as string))}
        />
      )}
      {isOpen2 && (
        <UserEditModal
          isOpen={isOpen2}
          onClose={onClose2}
          companyId={id}
          editUser={editUser}
          getUsers={() => getUsers(id ?? (user.company_id as string))}
        />
      )}
      {isOpen3 && (
        <UserEvaluateModal
          isOpen={isOpen3}
          onClose={onClose3}
          selectedUsers={selectedUsers}
          sectors={sectors}
          users={users}
        />
      )}

      <Flex width="100%">
        <Text fontSize={26} fontWeight="600" marginTop="2.5rem">
          Cadastrados
        </Text>
      </Flex>
      <Flex
        width="100%"
        boxShadow="0 0 0.0625rem #7a7a7a"
        borderRadius="0.3125rem"
        flexDirection="column"
        minHeight="31.25rem"
        marginBottom={'3.125rem'}
        marginTop={'1.25rem'}
      >
        <Flex padding="1.25rem">
          <Flex margin="auto 1.25rem auto" gap="0.625rem" marginLeft="auto">
            {selectedUsers.length > 0 && (
              <Button onClick={onOpen3} colorScheme="blackAlpha">
                Avaliar
              </Button>
            )}
            <Button onClick={onOpen} bgColor="#fdc300" color="white">
              Adicionar
            </Button>
            <Button colorScheme="blackAlpha" padding={0}>
              <Link
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                isExternal
                href={`${process.env.REACT_APP_URL}/company/register/${id}`}
                padding={'0.5rem 1rem'}
                color="white"
              >
                Tabela
              </Link>
            </Button>
          </Flex>
        </Flex>
        <Divider color="black" />
        <UserGrid
          users={users}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          hasSelect={user.type_account !== 2}
          editOpen={(user) => {
            setEditUser(user)
            onOpen2()
          }}
        />
      </Flex>
    </Flex>
  )
}

export default User
