import { Checkbox, Flex, Text, Button } from '@chakra-ui/react'

import React, { useEffect, useState, Fragment, useMemo } from 'react'
import { useEvaluationSelectorStore } from 'stores/evaluationSelector'
import CompanySelector from './CompanySelector'
import EvaluationSelectorDiv from './EvaluationSelector'
import SectorSelector from './SectorSelector'
import { useDisclosure } from '@chakra-ui/hooks'
import { Modal, ModalBody, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton } from '@chakra-ui/react'
import CourseSelector from './CourseSelector'
import { useParams } from 'react-router'
import ScannerSelector from './ScannerSelector'
import { useAuthStore } from 'stores/authStore'


export default function EvaluationSelector() {
  const { user } = useAuthStore()
  const { clearSelected, setSelectedCompanies, setSelectedEvaluations, setEvaluations } = useEvaluationSelectorStore()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [checked, setChecked] = useState<number>(0)
  const { id } = useParams<{ id: string }>()

  const onlySector = useMemo(() => {
    return ['10', '8'].includes(id)
  }, [id])

  useEffect(() => {
    if (user.type_account !== 2) return
    setSelectedCompanies([
      {
        id: user.company_id,
        commercial_name: user.company_name ?? '',
      },
    ])
  }, [user])

  useEffect(() => {
    setSelectedEvaluations([])
    setEvaluations([])
  }, [id])


  return (
    <>
      <Button colorScheme="yellow" onClick={onOpen} w="max-content">
        Filtros
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="max-content" userSelect="none">
          <ModalHeader>Filtros</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" flexDirection="column" gap="1rem">
            <Flex maxW="18.75rem">
              {['Empresa', 'Treinamento'].map((text, index) => (
                <Fragment key={index}>
                  <Checkbox
                    ml={index === 1 ? 'auto' : '0'}
                    size="sm"
                    isChecked={checked === index}
                    onChange={() => {
                      clearSelected()
                      setChecked(index)
                    }}
                  />
                  <Text fontSize="0.75rem" ml="0.25rem">
                    {text}
                  </Text>
                </Fragment>
              ))}
            </Flex>

            {user.type_account === 1 && checked === 0 && <CompanySelector />}

            {user.type_account === 1 && checked === 1 && <CourseSelector />}

            {checked === 0 && <SectorSelector />}

            {!onlySector && <EvaluationSelectorDiv />}

            {id === '-1' && <ScannerSelector />}

            <Button onClick={clearSelected} colorScheme="yellow" mt="auto" maxW="18.75rem">
              Limpar
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
