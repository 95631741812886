import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import Logo from 'assets/logowhite.png'
import { ButtonContainer } from 'components/Questions/ButtonContainer'
import QuestionComp from 'components/Questions/QuestionComp'
import QuestionCompOpen from 'components/Questions/QuestionCompOpen'
import useEvaluation from 'hooks/useEvaluation'
import React, { useEffect, useState } from 'react'
import { SubmitErrorHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from 'services/api'
import openQuestions from './openQuestions.json'
import questions from './questions.json'

interface IParamsProps {
  setor_id: string
  id: string
  time: string
}

type FormMapaOperacional = Record<number, string[][]>

const totalPages = 5

const getAnswers = (data: FormMapaOperacional) => {
  const final: { question: string; response: string }[] = []
  const pages = Object.values(data)

  pages.forEach((page, pageIndex) => {
    page.forEach((question, questionIndex) => {
      const questionNumber = `${pageIndex}.${questionIndex}`
      question.forEach((answer, answerIndex) => {
        if (!answer) return
        if (!answerIndex) {
          final.push({ question: `${questionNumber}`, response: answer })
        } else {
          final.push({ question: `${questionNumber}.1`, response: answer })
        }
      })
    })
  })
  return final
}

const QuestionMapaOperacionalDaAreaNew: React.FC = () => {
  const [page, setPage] = useState(1)
  const { handleSubmit, control } = useForm<FormMapaOperacional>()
  const [sector, setSector] = useState<string>('XXXXX')

  const { id } = useParams<IParamsProps>()

  const { evaluationData, registerResponses } = useEvaluation({ id })

  useEffect(() => {
    async function searchArea(): Promise<void> {
      if (!evaluationData?.rated_sector) return
      try {
        const { data } = await api.get(`sectors/${evaluationData?.rated_sector}`)
        setSector(data.sector_name)
      } catch (err) {
        toast({
          title: 'Erro ao buscar setor',
          status: 'error',
          duration: 10000,
          isClosable: true,
        })
      }
    }
    searchArea()
  }, [evaluationData])

  function handleNext(data: FormMapaOperacional) {
    if (page !== totalPages) {
      window.scrollTo(0, 0)
      return setPage(page + 1)
    }
    onSubmit(data)
  }

  async function onSubmit(data: FormMapaOperacional) {
    await registerResponses(getAnswers(data))
  }

  const handleInvalid: SubmitErrorHandler<FormMapaOperacional> = (evt) => {
    const pages = Object.values(evt)
    pages.forEach((page) => {
      if (!page) return
      page.forEach((question) => {
        if (!question) return
        question.forEach((answer) => {
          if (!answer) return
          toast.error(answer.message)
        })
      })
    })
  }

  return (
    <Flex w="100%" h="100vh" justify="center">
      <Box maxWidth={['100%', '100%', '62.5rem']}>
        <Flex
          justify="center"
          w={['100%', '100%', '62.5rem']}
          bg="#f8bb03"
          flexDirection={['column']}
          padding="1.25rem"
        >
          <Flex justify="space-around" align="center" w={['100%', '100%', '62.5rem']} flexDirection={['column', 'row']}>
            <Image w={['11.25rem', '14.375rem']} h={['8.125rem', '9.375rem']} src={Logo} alt="Logo White" />
            <Text color="#FFF" fontSize={['1.5rem', '2.3rem']}>
              Mapa Operacional da Área
            </Text>
          </Flex>
          <Box marginX="2.5rem" marginTop="1.25rem">
            <Text align="left" maxWidth="43.75rem" fontWeight="600">
              Para cada afirmativa abaixo assinale a resposta de acordo com a sua opinião.
            </Text>
            <Text fontWeight="600" display="inline-block">
              O setor que você esta avaliando é:
              <Text as={'span'} fontWeight="800">
                {` ${sector}`}
              </Text>
            </Text>
          </Box>
        </Flex>
        <>
          <Flex w={['100%', '100%', '62.5rem']} marginBottom="2.5rem" bg="#FFF" flexDirection="column">
            <>
              <form onSubmit={handleSubmit(handleNext, handleInvalid)}>
                {page !== totalPages && (
                  <>
                    <Flex margin={['0.625rem 2.5rem', '0.625rem 3.75rem']}>
                      <Text color="red">*</Text>
                      <Text>Obrigatória</Text>
                    </Flex>
                    {questions[page - 1].map((question, index) => (
                      <QuestionComp
                        key={question.text}
                        question={question}
                        index={index}
                        control={control}
                        page={page}
                      />
                    ))}
                  </>
                )}
                {page === totalPages &&
                  openQuestions.map((question, index) => (
                    <QuestionCompOpen
                      key={question.text}
                      question={question.text}
                      name={`5.${index}.0`}
                      index={index}
                      radio={question.radio}
                      control={control}
                    />
                  ))}
                <ButtonContainer actualPage={page} totalPages={5}>
                  {page !== 1 && (
                    <Button
                      w="10rem"
                      bg="#f8bb03"
                      color="#fff"
                      fontWeight="500"
                      fontSize="1.3rem"
                      onClick={() => setPage((prev) => prev - 1)}
                    >
                      Voltar
                    </Button>
                  )}
                  <Button type="submit" w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem">
                    {page !== totalPages ? 'Avançar' : 'Enviar'}
                  </Button>
                </ButtonContainer>
              </form>
            </>
          </Flex>
        </>
      </Box>
    </Flex>
  )
}

export default QuestionMapaOperacionalDaAreaNew
